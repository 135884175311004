@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;900&Libre+Franklin:wght@400;700;900&display=swap');
#root {
    max-width: 100vw;
    overflow: hidden;
    
}
html {
    min-width: 320px;   
    overflow-x: hidden;
}
body {
    font-family: 'Libre Franklin', sans-serif;
    font-size: 13pt;
    margin-top: 0 !important;
    
}
p {
    font-family: 'Nunito Sans', sans-serif;
}
button:focus {
    outline: 0;
}
.pageGrid {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 10px;
}
.bg-primary {
    background: #004f70;
}
.text-primary {
    color: #004f70;
}
.bg-secondary-half {
    background: #e6ed7f;
}
.bg-secondary {
    background: #cedc00;
}
.text-secondary {
    color: #cedc00;
}
.triangle {
    clip-path: polygon(100% 50%, 0 0, 0 100%);
}
.formControl {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 10px;
}
.formControl {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 100%;
    margin: 5px 0;
}
canvas:focus {
    outline: 0 !important;
}
.pageArticle h2 {
    font-size: 1.5em;
    font-weight: 700;
    color: #cedc00;
}
.pageArticle h2 i {
    font-style: normal;
    font-weight: 400;
}
.pageArticle ul li {
    list-style: circle;
    margin-bottom: 15px;
}
.pageArticle ul {
    margin: 20px 0;
    padding-left: 30px;
}
.pageArticle h3 {
    font-size: 1.2em;
    font-weight: 700;
    color: #004f70;
}
.pageArticle strong {
    color: #004f70;
}
.border-primary {
    border-color: #cedc00;
}
.pageArticle h4 {
    font-size: 1.1em;
    font-weight: 700;
    color: #004f70;
}
#video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 50%;
  min-height: 50%;
}

.VideoBg-module_video__2IPmN {
    height: 100%;
    width: 100%;
}

#video video {
    object-fit: fill;
    left: 0 !important;
}

.mobileMenuBt[data-active="true"] span:first-child {
    transform: rotate(45deg);
    top: 0;
    margin-top: 20px;
    position: absolute;
}
.mobileMenuBt[data-active="true"] span:nth-child(3) {
    transform: rotate(-45deg);
    top: 0;
    margin-top: 20px;
    position: absolute;
}
.mobileMenuBt[data-active="true"] span:nth-child(2) {
    opacity: 0;
}
textarea {
    min-height: 100px;
}

p a {
    color: #0984e3;
    font-weight: 600;
}
.notFixed .VideoBg-module_wrapper__3FlD_ {
    position: absolute;
}

.about h2 {
    font-size: 30px;
    font-weight: 700;
    color: #004f70;
    line-height: 1.2;
    margin-bottom: 30px;
}
.about h2 em {
    color: #cedc00;
    font-style: normal;
    font-weight: 700;

}
.oddRow {
    background: #f1f1f1;
}
.subProductListTrigger .subProductList {
    display: none;
}
.subProductListTrigger:hover .subProductList {
    display: block;
}
.subProductListItem i {
    font-style: normal;
    font-weight: 400;
}
.dropdown-item:hover {
    background: #004f70;
    color: #fff;
}
.dropdown-item a {
    display: block;
}
.dropdownSub {
    display: none;
}.dropdownSubTrigger:hover .subLabel {
    background: #004f70;
    color: #fff;
}
.dropdownSubTrigger:hover .dropdownSub {
    display: block;
    position: absolute;
    width: 200px;
    left: 100%;
    top: 0;
}
.languageSub:hover {
    background: #004f70;
    color: #fff;
}
.sideBar i{
    font-style: normal;
}


.policy h2,
.policy h3,
.policy h4 {
    color: #004f70;
    font-weight: 700;
    font-size: 1.5rem;
    margin-top: 30px;
}
.homeNotFixed .VideoBg-module_video__2IPmN {
    display: block;
}