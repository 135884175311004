	/*
  	Flaticon icon font: Flaticon
  	Creation date: 19/06/2020 11:19
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;
}

.flaticon-almond:before { content: "\f100"; }
.flaticon-nuts:before { content: "\f101"; }
.flaticon-peanut:before { content: "\f102"; }
.flaticon-peas:before { content: "\f103"; }
.flaticon-peas-1:before { content: "\f104"; }
.flaticon-cheeses:before { content: "\f105"; }
.flaticon-milk:before { content: "\f106"; }
.flaticon-chicken:before { content: "\f107"; }
.flaticon-cow:before { content: "\f108"; }
.flaticon-pig:before { content: "\f109"; }
.flaticon-menu:before { content: "\f10a"; }
.flaticon-letter:before { content: "\f10b"; }
.flaticon-clip:before { content: "\f10c"; }
.flaticon-phone:before { content: "\f10d"; }
.flaticon-calendar:before { content: "\f10e"; }
.flaticon-pin:before { content: "\f10f"; }
.flaticon-mail:before { content: "\f110"; }
.flaticon-setup:before { content: "\f111"; }
.flaticon-global:before { content: "\f112"; }
.flaticon-calendar-1:before { content: "\f113"; }
.flaticon-play-button:before { content: "\f114"; }
.flaticon-earth:before { content: "\f115"; }
.flaticon-search:before { content: "\f116"; }
.flaticon-mail-1:before { content: "\f117"; }
.flaticon-download:before { content: "\f118"; }
.flaticon-left-arrow:before { content: "\f119"; }
.flaticon-next:before { content: "\f11a"; }
.flaticon-back:before { content: "\f11b"; }
.flaticon-right-arrow:before { content: "\f11c"; }
.flaticon-upload:before { content: "\f11d"; }
.flaticon-upload-1:before { content: "\f11e"; }
.flaticon-download-1:before { content: "\f11f"; }
.flaticon-download-2:before { content: "\f120"; }
.flaticon-next-1:before { content: "\f121"; }
.flaticon-back-1:before { content: "\f122"; }
.flaticon-comment:before { content: "\f123"; }
.flaticon-phone-1:before { content: "\f124"; }
.flaticon-comment-1:before { content: "\f125"; }
.flaticon-comment-2:before { content: "\f126"; }
.flaticon-mailbox:before { content: "\f127"; }
.flaticon-postcard:before { content: "\f128"; }
.flaticon-mail-2:before { content: "\f129"; }
.flaticon-tick:before { content: "\f12a"; }
.flaticon-search-1:before { content: "\f12b"; }
.flaticon-pin-1:before { content: "\f12c"; }
.flaticon-multimedia:before { content: "\f12d"; }
.flaticon-smartphone:before { content: "\f12e"; }
.flaticon-plus:before { content: "\f12f"; }
.flaticon-close:before { content: "\f130"; }
.flaticon-plus-1:before { content: "\f131"; }
.flaticon-home:before { content: "\f132"; }
.flaticon-pin-2:before { content: "\f133"; }
.flaticon-origami:before { content: "\f134"; }
.flaticon-question:before { content: "\f135"; }
.flaticon-smartphone-1:before { content: "\f136"; }
.flaticon-search-2:before { content: "\f137"; }
.flaticon-cross:before { content: "\f138"; }
.flaticon-like:before { content: "\f139"; }
.flaticon-bell:before { content: "\f13a"; }
.flaticon-tick-1:before { content: "\f13b"; }
.flaticon-alert:before { content: "\f13c"; }
.flaticon-minus:before { content: "\f13d"; }
.flaticon-print:before { content: "\f13e"; }
.flaticon-magnifying-glass:before { content: "\f13f"; }
.flaticon-filter:before { content: "\f140"; }
.flaticon-menu-1:before { content: "\f141"; }
.flaticon-raisins:before { content: "\f142"; }